import React, { useMemo, useEffect, useState, useCallback } from "react";
import { FormGroup, Input } from "reactstrap";

import Notification from "../Notification";
import Loader from "../Loader";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import { getObject, concatenatePaths } from "../../util/mapObject";
import getPathFromId from "../../util/getPathFromId";
import { useJnx } from "../../util/jnx";
import useOptionsLookup from "./hooks/useOptionsLookup";
import CollapsableTreeSelect, { makeTreeNode, organizeTreeNodes, visitTrees } from "../CollapsableTreeSelect";
import { convertIfNumber } from "../JsonTreeEditor/util";
import { useDebouncedEffect } from "../../hooks/useDebounceEffect";

function LookupFormField(props) {
    const {
        formData,
        formContext,
        formContext: {
            setFormDataValues,
            sideChannel
        },
        disabled,
        readonly: propReadonly,
        schema: {
            title,
            lookup,
            type
        },
        idSchema: { $id } = {},
        uiSchema: {
            'akc:requiredIfVisible': akcRequiredIfVisible,
            'ui:readonly': uiReadonly,
            'ui:readonlyIf': uiReadonlyIf,
            'ui:onSelect': onSelectExpr,
        },
        // title,
        required: requiredProp,
        onChange: propOnChange
    } = props;
    const readonly = propReadonly || uiReadonly;
    const required = requiredProp || akcRequiredIfVisible;
    const {
        resource = "Options",
        setObjectAs,
        allowNoValue = true,
        noValue = '',
        parentId,
        collapsable, collapseLevel,
        label: labelExpr,
        id: idExpr,
        firstSelected = false,
        defaultValue: defaultValueExpr,
    } = lookup || {};
    const dataPath = useMemo(() => getPathFromId($id), [$id]);

    const objectPath = useMemo(
        () => setObjectAs ? concatenatePaths(dataPath, `..${setObjectAs}`) : null,
        [dataPath, setObjectAs]
    );
    const functionBinds = useMemo(() => ({ set: setFormDataValues }), [setFormDataValues]);
    const onSelectJnx = useJnx(onSelectExpr, { functionBinds });

    const rootFormData = useSideChannelSubscription(sideChannel, 0);

    const {
        options: lookupOptions, loadingOptions, errorLoadingOptions
    } = useOptionsLookup({
        lookup,
        rootFormData,
        path: dataPath,
        formContext
    });

    const options = useParsedOptions(lookupOptions, lookup);

    const defaultValueJnx = useJnx(defaultValueExpr);
    const defaultValue = useMemo(() => (
        defaultValueJnx && defaultValueJnx.eval(rootFormData || {}, '', {
            root: rootFormData,
            formContext,
        })
    ), [rootFormData, formContext, defaultValueJnx]);

    // Expand with other converters later
    // Check if it SHOULD be converted, then if it CAN be converted.
    const parseFormData = (formData) => {
        switch (type) {
            case "number": {
                return convertIfNumber(formData);
            }
            default: {
                return formData;
            }
        }
    }

    const [dataValue, setDataValue] = useState(parseFormData(defaultValue || formData));
    useEffect(() => {
        if (formData !== undefined && formData !== null) {
            setDataValue(parseFormData(formData))
        }
    }, [formData])

    useDebouncedEffect(() => {
        propOnChange(dataValue);
    }, [dataValue], 50);

    const onChange = ({ target: { value } }) => setDataValue(parseFormData(value));

    const labelJnx = useJnx(labelExpr);
    const idJnx = useJnx(idExpr);

    const readonlyIfJnx = useJnx(uiReadonlyIf);
    const readOnlyIf = useMemo(() => (
        readonlyIfJnx && readonlyIfJnx.eval(rootFormData || {}, '', {
            root: rootFormData,
            formContext,
        })
    ), [dataValue, rootFormData, formContext]);

    useEffect(() => {
        if (!options) {
            return;
        }

        const object = (options || []).filter(option => {
            const parsedValue = convertIfNumber(formData);
            return option.id === parsedValue;
        })[0]?.item;

        if (!objectPath) {
            return;
        }

        setFormDataValues({ [objectPath]: object });
    }, [dataValue, options, objectPath]);

    // Detect and handle circular references (In create new shift, location lookup needs deep comparison to avoid multiple rerenders.)
    function safeStringify(obj, replacer = null, space = 2) {
        const cache = new Set();
        const result = JSON.stringify(obj, (key, value) => {
            if (typeof value === 'object' && value !== null) {
                if (cache.has(value)) {
                    // Circular reference found, return a placeholder string
                    return '[Circular]';
                }
                cache.add(value);
            }
            return value;
        }, space);
        cache.clear();
        return result;
    }

    const memoizedOptions = useMemo(() => options, [safeStringify(options)]);
    const memoizedDataValue = useMemo(() => dataValue, [safeStringify(dataValue)]);
    const memoizedOnSelectJnx = useCallback(onSelectJnx, [onSelectJnx]);

    useEffect(() => {
        if (!memoizedOptions) {
            return;
        }

        const object = (memoizedOptions || []).filter(option => {
            const parsedValue = convertIfNumber(memoizedDataValue);
            return option.id === parsedValue;
        })[0]?.item;

        if (object && dataPath && memoizedOnSelectJnx) {
            setTimeout(() => {
                memoizedOnSelectJnx.eval(object, '', { fieldPath: dataPath, object });
            }, 500);
        }

    }, [memoizedOptions, memoizedDataValue, memoizedOnSelectJnx]);

    const isDisabled = readonly || readOnlyIf || disabled;

    const fSelected = useMemo(() => {
        return dataValue ? (typeof(dataValue) === "object" && idJnx ? idJnx.eval(dataValue) : dataValue ) : ((firstSelected && !dataValue && idJnx && options) ? idJnx.eval(options?.[0]) : null)  
    }, [firstSelected, dataValue, idJnx, options]);

    return (
        <FormGroup disabled={readonly || readOnlyIf || disabled}>
            {title !== " " ? <label className="control-label" htmlFor="root_preferredBranchId">
                {title}{required ? <span className="required">*</span> : null}
            </label> : null}
            {loadingOptions ? (<div>
                <Loader>Loading {resource}</Loader>
            </div>) : (errorLoadingOptions ? (<Notification color="danger">
                <div>{errorLoadingOptions.message}</div>
            </Notification>) : ((parentId && collapsable) ? (<CollapsableTreeSelect
                disabled={isDisabled} defaultCollapseLevel={collapseLevel}
                required={required}
                value={dataValue}
                rootNodes={options}
                onChange={onChange}
            />) : (

                <Input type="select" disabled={isDisabled} required={required} value={fSelected ?? ""}  onChange={onChange}>
                    {allowNoValue ? <option value={undefined}>{noValue}</option> : null}
                    {(options || []).map((option, idx) => (
                        <option key={idx} value={idJnx ? idJnx.eval(option.item) : option.id}>{labelJnx ? labelJnx.eval(option.item) : option.label}</option>
                    ))}
                </Input>
            )))}
        </FormGroup>
    );
}

export function useParsedOptions(options, {
    parentId, id, label
}) {
    const tsOptions = useMemo(() => {
        if (!options) return null;
        const tsOptions = options.map(item => makeTreeNode(item, id, label, parentId));

        if (parentId) {
            const rootNodes = organizeTreeNodes(tsOptions);
            visitTrees(rootNodes);
            tsOptions.sort((a, b) => a.visitIdx - b.visitIdx);
            return rootNodes;
        }

        return tsOptions;
    }, [options, parentId]);
    return tsOptions;
}

export default LookupFormField;